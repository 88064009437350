<template>
  <v-app>
      <v-dialog v-model="dialog" max-width="800" persistent>
          <v-card>
            <v-toolbar dark>
              <v-card-title class="headline">
                Online inquiry
                <hr>
              </v-card-title>

              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-spacer></v-spacer>
                <v-btn icon  @click="hideModal">
                  <i class="fas fa-times"></i>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        md="12"
                        sm="12"
                        lg="12"
                        xs="12"
                        cols="12"
                    ><b>Full Name:</b> {{message.first_name}} {{message.last_name}}</v-col>
                    <v-col md="12" sm="12" lg="12" xs="12" cols="12"><b>Phone:</b> {{message.phone}}</v-col>
                    <v-col md="12" sm="12" lg="12" xs="12" cols="12"><b>Email:</b> {{message.email}}</v-col>
                    <v-col md="12" sm="12" lg="12" xs="12" cols="12"><b>Subject:</b> {{message.subject}}</v-col>
                    <v-col cols="12">
                      <b>Message:</b>
                      <p v-html="message.message"></p>
                    </v-col>
                  </v-row>
                  <hr />
                  <div class="row">
                    <!--        <div class="col-12 mt-3">-->
                    <!--          <h3>Actions</h3>-->
                    <!--          <hr />-->
                    <!--        </div>-->
                    <div class="col-3">
                      <v-switch
                          v-model="message.is_read"
                          label="Marked as Read">
                      </v-switch>
                    </div>
                    <div class="col-3">
                      <v-switch
                          v-model="message.replied"
                          label="Marked as Replied">
                      </v-switch>

                    </div>
                    <!--                      <div class="col-12 text-right">
                                              <v-btn class="btn btn-primary text-white"  v-if="checkIsAccessible('online-inquiry', 'edit')"  @click="updateContactus">Save</v-btn>
                                              <v-btn class="btn btn-standard mr-2" @click="hideModal">Cancel</v-btn>

                                          </div>-->
                  </div>
                </v-container>
              </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  class="btn btn-standard cancel-btn"
                  depressed
                  @click="hideModal">
                Cancel
              </v-btn>
              <v-btn
                  class="text-white ml-2 btn btn-primary"
                  depressed
                  dark  v-if="checkIsAccessible('online-inquiry', 'edit')"
                  @click="updateContactus()">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>

  </v-app>
</template>
<script>
import ContactService from "@/core/services/contact/ContactService";

const contact = new ContactService();

export default {
  props: ["message_id"],
  name: "message-detail",
  data() {
    return {
        dialog:false,
      levels: [],
      message: {}
    };
  },
  mounted() {
    // this.getLevels();
  },
  methods: {
    getMessage(id) {
      // console.log(id)
      contact.show(id).then(response => {
        this.message = response.data.contact;
        this.message.is_active ? (this.message.is_active = true) : false;
        this.dialog = true;
      });
    },
    showModal(id) {
        this.getMessage(id);

    },
    hideModal() {

      this.teacher = [];
      this.message = {};
      this.dialog = false;
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    getTeacherList() {
      teacherService.all(this.message_id).then(response => {
        let teacher = response.data.data;
        if (teacher.length > 0) {
          teacher.forEach(ele => {
            this.teacher.push(ele.user_id);
          });
        }
      });
    },
    updateContactus() {
      contact.update(this.message.id, this.message).then(response => {
        this.$emit("close_dialog");
      });
    }
  }
};
</script>
