<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card ">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Lead & inquires</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Lead & inquires
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn @click="exportData()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-file-export</v-icon>&nbsp;
                    Export
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field label="Search by name, subject, email, or phone" type="text" dense outlined
                    v-on:keyup.enter="getMessages()" v-model="search.title" placeholder="Name, Email, Subject, Phone" />
                </v-col>

                <v-col cols="12" md="4">
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="search.publish_date_from" label="From Date" v-bind="attrs"
                        v-on:keyup.enter="getMessages()" v-on="on" outlined dense></v-text-field>
                    </template>
                    <v-date-picker v-model="search.publish_date_from" @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-col>
                
                <v-col cols="12" md="4">
                  <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                    offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="search.publish_date_to" label="To Date" v-bind="attrs"
                        v-on:keyup.enter="getMessages()" v-on="on" outlined dense></v-text-field>
                    </template>
                    <v-date-picker v-model="search.publish_date_to" @input="menu = false"></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="4">
                  <v-select outlined dense v-on:keyup.enter="getMessages()" v-model="search.filter_by" item-value="value"
                    item-text="text" :items="[
                      { text: 'Read', value: 'read' },
                      { text: 'Replied', value: 'replied' },
                      { text: 'Download', value: 'download' },
                      'read', 'replied', 'download']" label="Filter By">

                  </v-select>
                </v-col>

                <v-col cols="12" md="4" v-if="search.filter_by=='download'">
                  <v-select outlined dense v-on:keyup.enter="getMessages()" v-model="search.download_id" item-value="id" :items="private_downloads"
                            item-text="title"  label="Downloaded file">

                  </v-select>
                </v-col>

                <v-col cols="12" sm="12" md="8" class="text-right">
                  <v-btn @click.prevent="getMessages()" class="btn btn-primary btn-search" :loading="loading" dark>
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="isLoading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table table-stripe">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Date</th>
                    <th class="px-3 wrap-column">Type</th>
                    <th class="px-3 wrap-column">Information</th>
                    <th class="px-3 wrap-column">Status</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>

                </thead>
                <tbody>
                  <tr v-show="contacts.length > 0" v-for="(message, index) of contacts" :key="index">
                    <td class="px-3 wrap-column">
                      <a href="#" @click="openDialog(message.id)">{{ message.date }}</a> 
                    </td>
                    <td class="px-3 wrap-column">{{message.type}}</td>
                    <td class="px-3 wrap-column">
                      <strong>Name: </strong>{{ message.first_name }} {{ message.last_name }}
                      <div class="mt-2">
                        <strong>Email: </strong>{{ message.email }}
                      </div>
                      <div class="mt-2">
                        <strong>Phone: </strong> {{ message.phone }}
                      </div>

                      <div v-if="message.type=='download' &&  message.download" class="mt-2">
                        <strong>Downloaded file: </strong> <a :href="message.download.file_path" target="_blank">{{ message.download.title }}</a>
                      </div>


                    </td>
                    <td class="px-3 wrap-column">
                      <span class="badge badge-info" v-if="message.is_read">READ</span>
                      <span class="badge badge-danger" v-else>NOT READ</span>
                      <div class="mt-2">
                        <span class="badge badge-info" v-if="message.replied">REPLIED</span>
                        <span class="badge badge-danger" v-else>NOT REPLIED</span>
                      </div>
                    </td>

                    <td class="pr-0 text-center">
                      <b-dropdown size="sm" variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon" no-caret
                        right no-flip>
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i>
                            </span>
                          </slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover ">
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('online-inquiry', 'show')">
                            <a href="#" class="navi-link" @click="openDialog(message.id)">
                              <span class="navi-icon">
                                <i class="fas fa-eye"></i>
                              </span>
                              <span class="navi-text">View</span>
                            </a>
                          </b-dropdown-text>


                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>

                      <!--                        <span @click="openDialog(message.id)" class="cursor-pointer" title="view">-->
                      <!--                          <i class="fas fa-eye"></i>-->
                      <!--                        </span>-->
                    </td>
                  </tr>
                  <tr v-if="contacts.length == 0">
                    <td class="px-3 text-center" colspan="5">
                      <strong>No data available to display.</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading" v-if="contacts.length > 0" @input="getMessages" v-model="page"
                :total-rows="total" :per-page="perPage" first-number last-number></b-pagination>
            </div>
          </div>
          <Message ref="message" :message_id="message_id" @close_dialog="closeDialog"></Message>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import ContactService from "@/core/services/contact/ContactService";
import DownloadService from "../../../core/services/download/DownloadService";
import { API_URL } from "@/core/config";

import Message from "./CreateOrUpdate";

const contact = new ContactService();
const download = new DownloadService();

export default {
  name: "Contacts-messages",
  components: {
    Message
  },
  data() {
    return {
      isLoading: false,
      menu2: false,
      menu: false,
      contacts: [],
      message_id: null,
      search: {},
      page: 1,
      total: 0,
      perPage: 25,
      loading: false,
      private_downloads:[]
    };
  },
  mounted() {
    this.getMessages();
    this.getPrivateDownloads();
  },
  methods: {
    getMessages() {
      this.isLoading = true;
      contact.paginate(this.search, this.page).then(response => {
        this.contacts = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = parseInt(response.data.meta.per_page);
      }).finally(() => {
        this.isLoading = false;
      });
    },

    getPrivateDownloads()
    {
      download.getPrivateDownloads().then(response => {
            this.private_downloads = response.data.data;
      });
    },
    openDialog(id = null) {
      this.message_id = id;
      this.$refs["message"].showModal(id);
    },
    closeDialog(id) {
      this.message_id = id;
      this.$refs["message"].hideModal();
      this.getMessages();
    },
    _reset() {
      this.search = {};
      this.getMessages();
    },

    exportData() {
      let url = API_URL + "user/resources-download-export-csv"

      if (this.search) {
        let query = new URLSearchParams(this.search).toString();
        if (query)
          url = url + '?' + query;
      }
      window.open(url)
    }

  }
};
</script>
